<template>
  <div class="controls">
    <div>
      <div class="list" v-for="(item, index) in list" :key="index">
        <div class="title">{{ item.title }}</div>
        <div>
          <van-grid square :border="false" :column-num="4">
            <van-grid-item v-for="(child, i) in item.children" :key="i">
              <router-link :to="{ path: child.url, query: child.query }">
                <div class="list-item">
                  <van-image :src="child.img" />
                  <div>{{ child.title }}</div>
                </div>
              </router-link>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Image as VanImage, Grid, GridItem } from "vant";
export default {
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      list: [
        {
          title: "控制模块",
          children: [
            {
              img: require("@/assets/mianban_room.svg"),
              title: "房间列表",
              url: "/SmartClassroom/RoomList",
              query: {
                active: 0,
              },
            },
            {
              img: require("@/assets/mianban_control02.svg"),
              title: "控制面板",
              url: "/SmartClassroom/AllEquipement",
              query: {
                roomId: -1,
              },
            },
            {
              img: require("@/assets/mianban_time.svg"),
              title: "内部考勤",
              url: "/SmartClassroom/Attendance",
            },
            {
              img: require("@/assets/mianban_tongji.svg"),
              title: "水电统计",
              url: "/SmartClassroom/Hydropower",
            },
            {
              img: require("@/assets/mianban_guangbo.svg"),
              title: "广播播报",
              url: "/SmartClassroom/Equipement",
              query: {
                active: 5,
              },
            },
          ],
        },
        {
          title: "设备管理",
          children: [
            {
              img: require("@/assets/mianban_jiance.svg"),
              title: "智能测试",
              url: "/SmartClassroom/DetectionIndex",
            },
            {
              img: require("@/assets/mianban_shebei.svg"),
              title: "设备列表",
              url: "/SmartClassroom/EquipmentList",
            },
            {
              img: require("@/assets/mianban_baojing.svg"),
              title: "报警管理",
              url: "/SmartClassroom/EquipmentAlarm",
            },
          ],
        },
        {
          title: "预约管理",
          children: [
            {
              img: require("@/assets/mianban_yuyue.svg"),
              title: "预约审核",
              url: "/SmartClassroom/AppointmentList",
            },
            {
              img: require("@/assets/mianban_shenqing.svg"),
              title: "申请预约",
              url: "/SmartClassroom/AddAppointment",
            },
            {
              img: require("@/assets/mianban_mingdang.svg"),
              title: "黑白名单",
              url: "",
            },
          ],
        },
        {
          title: "其他工具",
          children: [
            {
              img: require("@/assets/mianban_yuangong.svg"),
              title: "员工管理",
              url: "/SmartClassroom/StaffManagement",
            },
          ],
        },
      ],
    };
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../style/public.scss";

.controls {
  width: 100%;
  height: 100%;
  overflow: hidden;
  > div:first-child {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: $bgColor;
    padding-bottom: 80px;
  }
  .list {
    margin: 0 8px;
    margin-top: 8px;
    background: #fff;
    padding: 14px 16px;
    .title {
      font-size: 13px;
      font-weight: 400;
      color: #7f7f7f;
    }
    .van-grid {
      margin-top: 20px;
    }
    .list-item {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      > div:last-child {
        margin-top: 10px;
      }
    }
  }
}
</style>